/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */

.appui {
  font-family: 'Roboto', sans-serif;
}

.appui__btn {
  background: none;
  background-color: #ffffff;
  border: none;
  border: 1px solid #333333;
  padding: 8px 10px;
  border-radius: 5px;
}

.appui__auth-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appui__auth-btn:hover {
  background-color: rgba(0, 0, 0, 0.025);
  cursor: pointer;
}

.appui__auth-btn:active {
  background-color: rgba(0, 0, 0, 0.05);
}

.appui__auth-btn-text {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.appui__input-time {
  border: none;
  border: solid 1px rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 125px;
}

.appui__input-time:focus {
  outline-color: #12ab52;
}

.appui__input--error {
  border-color: #d32f2f;
}
